import { useCallback, useEffect, useMemo } from 'react';

import { JwtCompanyRole } from 'backend/types.codegen';

import { CiamGeneralError } from 'components/structural/ErrorBoundary';

import { useBackendClient } from 'contexts/backendClient';

import { StorageKey } from 'types/stateStore';

import { CUSTOMER_ROLE_IDENTIFIER } from 'utils/cookie';

import { useSelectedRoleFromCookie } from 'providers/IdentityProvider/useSelectedRoleFromCookie';
import { useGetCompanyRoles } from './useGetCompanyRoles';
import { useIdentity } from './useIdentity';
import { setStoredState } from './useStoredState';

export const useSelectedRole = () => {
  const [identity] = useIdentity();
  const [selectedRoleFromCookie, setSelectedRoleToCookie] = useSelectedRoleFromCookie();

  const client = useBackendClient();

  const { customerRole, companyRoles } = useGetCompanyRoles();
  const isValidRole = useCallback(
    (roleParam?: string | null): boolean =>
      !!roleParam &&
      ((roleParam === CUSTOMER_ROLE_IDENTIFIER && !!customerRole) ||
        (!!companyRoles &&
          companyRoles.flatMap(({ roles }) => roles).some((role) => role.id === roleParam))),
    [customerRole, companyRoles],
  );

  const setSelectedRole = useCallback(
    async (role: string) => {
      setSelectedRoleToCookie(role);
      setStoredState(StorageKey.PreviouslySelectedRole, role);
      await client.resetStore();
    },
    [client, setSelectedRoleToCookie],
  );

  const selectedRole = useMemo(() => {
    if (isValidRole(selectedRoleFromCookie)) {
      return selectedRoleFromCookie;
    }

    // No role selected, find first match
    let fallbackRole: string | undefined;
    if (customerRole) {
      fallbackRole = CUSTOMER_ROLE_IDENTIFIER;
    } else if (companyRoles) {
      fallbackRole = companyRoles
        .find(({ roles }) =>
          roles.some(({ role }) => role === JwtCompanyRole.ForteTravelAgencyClerk),
        )
        ?.roles.find(({ role }) => role === JwtCompanyRole.ForteTravelAgencyClerk)?.id;
      fallbackRole =
        fallbackRole ??
        companyRoles.at(0)?.roles.find((role) => role.role === JwtCompanyRole.ForteCorporateUser)
          ?.id;
    }
    return fallbackRole;
  }, [companyRoles, customerRole, isValidRole, selectedRoleFromCookie]);

  useEffect(() => {
    if (selectedRole && selectedRole !== selectedRoleFromCookie) {
      setSelectedRoleToCookie(selectedRole);
    }
  }, [selectedRole, selectedRoleFromCookie, setSelectedRoleToCookie]);

  const resolveUserProps = useCallback(
    (selectedRole: string | undefined) => {
      const selectedCompanyId =
        selectedRole !== CUSTOMER_ROLE_IDENTIFIER ? selectedRole?.split('-')?.at(0) : undefined;

      const selectedCompany = selectedCompanyId
        ? (companyRoles ?? []).find((c) => c.roles.some(({ id }) => selectedRole === id))
        : undefined;

      const roles = selectedCompany?.roles.map(({ role }) => role) || [];
      const selectedCompanyName = selectedCompany?.name;

      const isConsumerUser = selectedRole === CUSTOMER_ROLE_IDENTIFIER;
      const isCompanyUser = !!companyRoles?.some((role) =>
        role.roles.some(({ id, role }) => !role.includes('TRAVEL') && selectedRole == id),
      );
      const isTravelAgentUser = !!companyRoles?.some(({ roles }) =>
        roles.some(({ id, role }) => role.includes('TRAVEL') && selectedRole == id),
      );

      return {
        isCompanyUser,
        isConsumerUser,
        isTravelAgentUser,
        selectedCompanyId,
        selectedCompanyName,
        roles,
      };
    },
    [companyRoles],
  );

  return useMemo(() => {
    if (!identity?.isCiamUser) {
      return {
        colorIdentity: undefined,
        isCompanyUser: undefined,
        isConsumerUser: undefined,
        isTravelAgentUser: undefined,
        selectedCompanyId: undefined,
        selectedCompanyName: undefined,
        selectedRole: undefined,
        setSelectedRole: () => {
          throw new CiamGeneralError('Should not set selected role without identity');
        },
        loading: !identity, // set loading true when we don't have identity yet
        isValidRole: (_role?: string | null) => {
          return undefined;
        },
        roles: [] as JwtCompanyRole[],
      };
    }

    return {
      isValidRole,
      selectedRole,
      setSelectedRole,
      loading: !identity,
      ...resolveUserProps(selectedRole),
    };
  }, [identity, selectedRole, resolveUserProps, setSelectedRole, isValidRole]);
};
