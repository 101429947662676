import { useCallback, useEffect, useState } from 'react';

import { useOnMount } from 'hooks/useOnMount';

import {
  getSelectedRole as getSelectedRoleFromCookie,
  setSelectedRole as setSelectedRoleCookie,
} from 'utils/cookie';

const POLL_INTERVAL = 100;

export const useSelectedRoleFromCookie = (): [string | undefined, (role?: string) => void] => {
  const [selectedRoleState, setSelectedRoleState] = useState<string | undefined>();

  useOnMount(() => {
    setSelectedRoleState(getSelectedRoleFromCookie());
  });

  const setSelectedRole = useCallback(
    (role?: string) => {
      setSelectedRoleState(role);
      setSelectedRoleCookie(role);
    },
    [setSelectedRoleState],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedRoleState(getSelectedRoleFromCookie());
    }, POLL_INTERVAL);

    return () => clearInterval(interval);
  }, [setSelectedRoleState]);

  return [selectedRoleState, setSelectedRole];
};
