import { OrderGroupJourneyFragment, SessionGroupJourneyFragment } from 'backend/types.codegen';

import { Maybe, hasOwnProperty } from 'types/utils';

import { isCancelled } from './journey';

const isOrderPassengerJourneyFragment = (
  obj: any,
): obj is {
  state: string;
} => hasOwnProperty(obj, 'state');

type GroupJourneyLike = {
  passengerJourneys?: Maybe<
    Array<
      | {
          id: string;
          state: string;
        }
      | { id: string }
    >
  >;
};

export const toGroupJourneyKey = (props: Maybe<GroupJourneyLike>) =>
  props?.passengerJourneys?.find((passengerJourney) => {
    if (isOrderPassengerJourneyFragment(passengerJourney)) {
      return !isCancelled({ state: passengerJourney.state });
    }
    // In sales session no journey is cancelled
    return true;
  })?.id;

export const isMatchingGroupJourney =
  (journeyKey: string | undefined) =>
  ({ passengerJourneys }: OrderGroupJourneyFragment | SessionGroupJourneyFragment) =>
    (passengerJourneys ?? []).findIndex(({ id }) => id === journeyKey) >= 0;
