'use client';

import { LOCALE } from 'constants/i18n';
import { LocalizationKey } from 'constants/i18n.messages';
import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

type Props = {
  locale: LOCALE;
  messages?: Record<LocalizationKey, string>;
  children: ReactNode;
};
export default function ClientIntlProvider({ locale, messages, children }: Props) {
  return (
    <IntlProvider messages={messages} locale={locale}>
      {children}
    </IntlProvider>
  );
}
