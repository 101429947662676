'use client';
import { useEffect } from 'react';

import { useScreenReaderAnnouncements } from '.';
import ScreenreaderText from '../ScreenreaderText';

export const ScreenReaderAnnouncements = () => {
  const [announcements, setAnnouncements] = useScreenReaderAnnouncements();

  const currentAnnouncement = announcements.at(0);

  useEffect(() => {
    if (!currentAnnouncement) return;
    // Remove each notification after the timeout
    const timeout = setTimeout(
      () => setAnnouncements((prevValue) => prevValue.slice(1)),
      currentAnnouncement.timeout ?? 1000,
    );

    return () => clearTimeout(timeout);
  }, [currentAnnouncement, setAnnouncements]);

  return (
    <ScreenreaderText>
      {currentAnnouncement && (
        <span aria-live={currentAnnouncement.ariaLive ?? 'assertive'} role="alert">
          {currentAnnouncement.content}
        </span>
      )}
    </ScreenreaderText>
  );
};
