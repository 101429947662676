import { useMemo } from 'react';

import { isStringArray } from 'types/typeGuards';
import { CancellationQueryParams } from 'types/types';

import { useLocation } from 'utils/navigation';
import queryString from 'utils/qs';
import { queryTypes, useQueryStates } from 'utils/queryState';

export enum CancellationFlow {
  vehicleCancellation = 'vehicleCancellation',
  passengerCancellation = 'passengerCancellation',
}

export enum CancelFlowSource {
  forms = 'forms',
  profile = 'profile',
}

const queryState = {
  orderNumber: queryTypes.string,
  journeyKey: queryTypes.string.withDefault(''),
  referenceNumber: queryTypes.string,
  source: queryTypes
    .stringEnum(Object.values(CancelFlowSource))
    .withDefault(CancelFlowSource.profile),
  flow: queryTypes
    .stringEnum(Object.values(CancellationFlow))
    .withDefault(CancellationFlow.passengerCancellation),
};

export const useCancellationQueryParams = (): Readonly<CancellationQueryParams> => {
  const location = useLocation();
  const [{ orderNumber, journeyKey, referenceNumber, source, flow }] = useQueryStates(queryState);

  return useMemo(() => {
    const { sp, sv } = queryString.parse(location.search);

    return {
      orderNumber: orderNumber ?? undefined,
      referenceNumber: referenceNumber ?? undefined,
      source,
      flow,
      journeyKey,
      sp: isStringArray(sp) ? sp : [],
      sv: isStringArray(sv) ? sv : [],
    };
  }, [location, orderNumber, journeyKey, referenceNumber, source, flow]);
};
