import { addDays } from 'date-fns';

import { JourneyState, PassengerType } from 'backend/types.codegen';

import { Maybe, isString } from 'types/utils';

import { createDate } from './date';

export const sanitizePassengerType = (str: unknown): PassengerType | undefined =>
  isString(str)
    ? Object.values(PassengerType).find((p) => p.toUpperCase() === str.toUpperCase())
    : undefined;

export const passengerCountByType = (
  passengers: { type: PassengerType }[],
): { [type in PassengerType]?: number } =>
  passengers.reduce<Partial<Record<PassengerType, number>>>(
    (passengerCount, passenger: { type: PassengerType }) => {
      passengerCount[passenger.type] = (passengerCount[passenger.type] ?? 0) + 1;
      return passengerCount;
    },
    {},
  );

export const isPastJourney = ({ arrivalTime }: { arrivalTime?: Maybe<string> }) => {
  const dayAfterArrival = addDays(createDate(arrivalTime), 1);
  return dayAfterArrival.getTime() < Date.now();
};

export const isCancelled = ({ state }: { state?: Maybe<JourneyState | string> }) => {
  return (
    state === JourneyState.Cancelled ||
    state === JourneyState.Refunded ||
    state === JourneyState.NotConfirmed
  );
};

export const isAllCancelled = (journeys: Maybe<{ state?: Maybe<JourneyState | string> }[]>) =>
  journeys && journeys.every(isCancelled);

export const hasJourneyStarted = ({ departureTime }: { departureTime: string }) =>
  createDate(departureTime) <= createDate();
