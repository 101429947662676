import { UserIdentity } from 'contexts/identity';
import { useLoggedInCookie } from 'providers/IdentityProvider/useLoggedInCookie';
import { StorageKey } from 'types/stateStore';
import { useStoredState } from './useStoredState';

export const useIdentity = (): [UserIdentity | undefined, () => void] => {
  const [identity, setIdentity] = useStoredState(StorageKey.UserIdentity);
  const isLoggedInCookie = useLoggedInCookie();

  return [isLoggedInCookie ? identity ?? undefined : undefined, () => setIdentity(null)];
};
