/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useIsMounted } from 'contexts/isMounted';
import { useVariable } from './useVariable';

const defaultOptions: { waitUntilHydrated: boolean } = {
  waitUntilHydrated: false,
};

export const useOnMount = (fn: () => void, options = defaultOptions) => {
  const { waitUntilHydrated } = options;
  const fnRef = useVariable(fn);
  const isHydrated = useIsMounted();

  useEffect(() => {
    if (!waitUntilHydrated || isHydrated) {
      fnRef.current();
    }
  }, [isHydrated]);
};
