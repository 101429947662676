'use client';
import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';

import { ModalProvider } from 'contexts/modal';

export const ModalHandler = ({ children }: { children: ReactNode }) => {
  const [modals, setModals] = useState<JSX.Element[]>([]);

  const showModal = useCallback(
    <T,>(fn: (onClose: (value: T) => void) => JSX.Element): Promise<T> =>
      new Promise<T>((resolve) => {
        const component = fn((value) => {
          setModals((prevModals) => prevModals.filter((m) => m !== component));
          resolve(value);
        });

        setModals((prevModals) => [...prevModals, component]);
      }),
    [],
  );

  const value = useMemo(() => ({ showModal }), [showModal]);

  return (
    <ModalProvider value={value}>
      {children}
      {modals.map((modal, index) => (
        <Fragment key={index}>{modal}</Fragment>
      ))}
    </ModalProvider>
  );
};
