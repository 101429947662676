import gql from 'graphql-tag';

export const OptionAvailabilityFragment = gql`
  fragment OptionAvailability on OptionAvailability {
    seatAvailability_v2
    accessibleSeatAvailability
    petSeatAvailability
    cabinAvailability
    petCabinAvailability
    accessibleCabinAvailability
  }
`;

export const DiscountCodeFragment = gql`
  fragment DiscountCode on DiscountCode {
    type
    usageType
    travelStartDate
    travelEndDate
  }
`;

export const CorporateOfferFragment = gql`
  fragment CorporateOffer on CorporateOffer {
    type
    placeType
    totalPrice
    nonRefundableAmount
    canCancelBeforeDeparture
    changeFreeOfCharge
  }
`;

const ExternalJourneyLegFragment = gql`
  fragment ExternalJourneyLeg on ExternalJourneyLeg {
    fromPlaceId
    fromPlaceName
    fromStopName
    fromStopAreaName
    toPlaceId
    toPlaceName
    toStopName
    toStopAreaName
    departureTime
    arrivalTime
    lineName
    lineOperator
    facilities
  }
`;

export const ExternalServiceFragment = gql`
  ${ExternalJourneyLegFragment}

  fragment ExternalServiceDetails on ExternalServiceDetails {
    bicycle
    externalJourneyLegs {
      ...ExternalJourneyLeg
    }
  }

  fragment ExternalService on ExternalService {
    id
    isExternalBusService
    busConnectionKey
    price
    fee {
      price
    }
    product
    details {
      ...ExternalServiceDetails
    }
  }
`;

const UserDetailsFragment = gql`
  fragment UserDetails on UserDetails {
    ... on ConsumerUserDetails {
      dateOfBirth
      firstName
      lastName
      gender
      postalCode
      countryCode
      mobilePhoneNumber
      otherPhoneNumber
      emailAddress
      consentMarketing
      consentB2BMarketing
      consentCustomerResearch
      serviceLanguage
      car {
        model
        registerNumber
        height
        length
        width
      }
    }
    ... on CorporateUserDetails {
      firstName
      lastName
      emailAddress
    }
    ... on EmployeeUserDetails {
      firstName
      lastName
      emailAddress
      mobilePhone
      employeeId
      department
      alias
    }
  }
`;

export const UserFragment = gql`
  ${UserDetailsFragment}
  fragment User on User {
    customerId
    isConsumer
    isCorporate
    isEmployee
    isTravelAgent
    isCiamUser

    details {
      ...UserDetails
    }
    roles
  }
`;

export const UserIdentityFragment = gql`
  fragment UserIdentity on User {
    customerId
    isAuthenticated
    isConsumer
    isCorporate
    isEmployee
    isVrEmployee
    isCiamUser
    firstName
    lastName
    customerRoles
    companyRoles {
      name
      name2
      roles {
        id
        role
        canPurchase
      }
    }
    isTramEmployee
    isPlEmployee
  }
`;

export const CancellationInsuranceOfferFragment = gql`
  fragment CancellationInsuranceOffer on CancellationInsuranceOfferResponse {
    ... on NoOffer {
      noOfferReason
    }
    ... on CancellationInsuranceOffer {
      id
      price
      coverage {
        outbound
        return
      }
    }
  }
`;

export const CancellationInsuranceFragment = gql`
  fragment CancellationInsurance on CancellationInsurance {
    id
    price
    totalPrice
  }
`;

export const VehicleFragment = gql`
  ${CancellationInsuranceFragment}

  fragment Vehicle on Vehicle {
    id
    passengerJourneyId
    vehicleDetails {
      height
      length
      vehicleType
      registerNumber
      manufacturer
      model
      vehicleType
    }
    vehicleLoadingDetails {
      loadedByVR
      vehicleLoadingStart
      vehicleLoadingEnd
      firstName
      lastName
      phoneNumber
    }
    cancellationInsuranceOffer {
      ...CancellationInsurance
    }
  }
`;

export const AvecraServicesFragment = gql`
  fragment AvecraServices on AvecraService {
    avecraGroupId
    purchaseType
    services {
      id
      product
      supplementalServices
      foodSelection1
      price
      state
    }
  }
`;
const AvecraOrdersFragment = gql`
  fragment AvecraOrders on LegWithMeals {
    departureStation
    arrivalStation
    meals {
      count
      drink
      product
      foodOption
      passengerLegIds
    }
  }
`;

export const SummaryFragment = gql`
  fragment LineItemProductSubItem on LineItemProductSubItem {
    count
    productType
    unitPrice {
      grossAmount
    }
    values {
      passengerType
    }
  }

  fragment LineItem on LineItem {
    productType
    productAttribute
    product
    serviceProvider
    count
    unitPrice {
      grossAmount
    }
    totalPrice {
      grossAmount
    }
    details
    subItems {
      type
      count
      key
    }
    productSubItems {
      ...LineItemProductSubItem
    }
    values {
      supplementalService
      foodSelection1
      passengerType
      usagesCount
      productName
      from
      to
    }
  }

  fragment Summary on Summary {
    total {
      grossAmount
    }
    breakdown {
      ...LineItem
    }
  }
`;

export const JourneyFeeFragment = gql`
  fragment JourneyFee on JourneyFee {
    id
    price
    product
  }
`;

export const TrainInfoFragment = gql`
  fragment TrainInfo on TrainInfo {
    wheelchairAccessible
    rampServiceAvailable
    services
    trainNumber
    trainType
    commercialLineId
    availability {
      ...OptionAvailability
    }
    route {
      station
      arrivalTime
      departureTime
    }
    productAvailability {
      name
      attribute
      availability
    }
  }

  ${OptionAvailabilityFragment}
`;

export const LegReservationFragment = gql`
  fragment LegReservation on Reservation {
    id
    placeNumber
    wagonNumber
    compartmentNumber
    position
    attribute
    floor
    cabinId
    services
    ticketClass
    product
    price
    reference
    placePrice
    discount {
      discountPercentage
      ids
      codes {
        type
        usageType
        travelStartDate
        travelEndDate
      }
    }
  }
`;

const AvecraProductFragment = gql`
  fragment AvecraProduct on AvecraProduct {
    product
    price
  }
`;

const AvecraOffersFragment = gql`
  fragment AvecraOffers on AvecraOffers {
    count
    products {
      ...AvecraProduct
    }
  }
  ${AvecraProductFragment}
`;

export const AvecraProductsPerLegFragment = gql`
  fragment AvecraProductsPerLeg on AvecraProductsPerLeg {
    legId
    productAvailability {
      ...AvecraOffers
    }
  }
  ${AvecraOffersFragment}
`;

const OrderLegFragment = gql`
  fragment OrderJourneyLeg on JourneyLeg {
    id
    legKey
    arrivalStation
    arrivalTime
    departureStation
    departureTime
    trainNumber
    trainType
    ticketViewUrl
    commercialTrainNumber
    reservation {
      ...LegReservation
    }

    additionalReservations {
      ...LegReservation
    }

    avecraServices {
      ...AvecraServices
    }

    bicycles {
      wagonNumber
    }

    trainInfo {
      ...TrainInfo
    }
  }
  ${AvecraServicesFragment}
  ${TrainInfoFragment}
  ${LegReservationFragment}
`;

const OrderPassengerJourneyFragment = gql`
  fragment OrderPassengerJourney on PassengerJourney {
    id
    passenger {
      type
      eligibleForCancellationInsurance
      linkedToPassenger
    }
    otherFees {
      id
      product
      price
    }
    petFees {
      id
      price
    }
    bicycleFees {
      id
      price
    }
    seriesTicketId
    seasonTicketId
    travelPassTicketId
    cancellationInsurance {
      id
      validUntil
    }
    legs {
      ...OrderJourneyLeg
    }
    state
    externalServices {
      ...ExternalService
    }
    discountCodes {
      ...DiscountCode
    }
  }

  ${OrderLegFragment}
  ${ExternalServiceFragment}
  ${DiscountCodeFragment}
`;

const GroupJourneyFragment = gql`
  fragment OrderGroupJourney on GroupJourney {
    groupId
    idWithDepartureStation
    departureStation
    departureTime
    arrivalStation
    arrivalTime
    usageEndTime
    usageStartTime
    trafficType
    hasJourneyStarted
    cancelStatus
    masterTicketViewUrl
    corporate {
      ...CorporateOffer
    }
    cabins {
      id
      legKey
      type
      attribute
      size
      totalPrice
      passengers {
        passengerLegId
        type
        reservation {
          id
          placeNumber
          wagonNumber
          compartmentNumber
          position
          attribute
          floor
          validUntil
          cabinId
        }
      }
    }
    vehicles {
      id
      passengerJourneyId
      vehicleDetails {
        height
        length
        registerNumber
        manufacturer
        model
        vehicleType
      }
      cancellationInsurance {
        id
        validUntil
      }
      vehicleLoadingDetails {
        firstName
        lastName
        phoneNumber
        loadedByVR
        vehicleLoadingStart
        vehicleLoadingEnd
      }
    }
    passengerJourneys {
      ...OrderPassengerJourney
    }
    summary {
      breakdown {
        product
        productType
        count
      }
    }
    additionalProducts {
      type
      count
    }
    avecraOrders {
      ...AvecraOrders
    }
    avecraOrdersPerLeg {
      ...AvecraOrders
    }
  }
  ${OrderPassengerJourneyFragment}
  ${AvecraOrdersFragment}
  ${CorporateOfferFragment}
`;

export const PaymentFragment = gql`
  fragment Payment on Payment {
    paymentId
    state
    invoice {
      singleInvoice
      editable
      passengers {
        journeyId
        reference
      }
    }
  }
`;

export const PriceElementFragment = gql`
  fragment PriceElement on PriceElement {
    type
    amount
    unpaidAmount
    status
    payUntil
  }
`;

const GroupFragment = gql`
  fragment Group on Group {
    state
  }
`;

const OrderSeriesTicketFragment = gql`
  fragment OrderSeriesTicket on SeriesTicket {
    bookingId
  }
`;

const CancellationSummaryFragment = gql`
  fragment CancellationSummary on CancellationSummary {
    status
    refundAmount
    nonRefundableAmount
    bookingCancellationSummaryList {
      amount
      cost
      amountToRefund
      name
    }
  }
`;

export const OrderFragment = gql`
  fragment Order on Order {
    orderNumber
    paymentIds
    groupJourneys {
      ...OrderGroupJourney
    }
    cancellationSummary(input: $cancellationSummary) {
      ...CancellationSummary
    }
    refundEligibility {
      eligibleForRefundWithCancellationInsurance
      nonEligibilityReason
    }
    payments {
      ...Payment
    }
    price {
      amount
      elements {
        ...PriceElement
      }
    }
    group {
      ...Group
    }
    seriesTickets {
      ...OrderSeriesTicket
    }
    owner {
      companyId
    }
    creator {
      companyType
    }
  }

  ${GroupJourneyFragment}
  ${PaymentFragment}
  ${PriceElementFragment}
  ${GroupFragment}
  ${OrderSeriesTicketFragment}
  ${CancellationSummaryFragment}
`;

export const TrafficDisruptionFragment = gql`
  fragment TrafficDisruption on TrafficDisruption {
    id
    incidentId
    subject
    body
    dates
    created
    modified
    tag
  }
`;

export const IncidentMessageFragment = gql`
  fragment IncidentMessage on IncidentMessage {
    id
    incidentId
    subject
    body
    start
    created
    tag
  }
`;

export const SeatChangeOffer = gql`
  fragment Discount on Discount {
    discountPercentage
    ids
  }

  fragment SeatReservationOffer on SeatReservationOffer {
    id
    price
    product
    commercialTrainNumber
    totalPrice
    discount {
      ...Discount
    }
    attribute
  }

  fragment AdditionalReservationOffer on AdditionalReservationOffer {
    id
    price
    product
    commercialTrainNumber
    totalPrice
    discount {
      ...Discount
    }
  }

  fragment SeatChangeOffer on SeatChangeOffer {
    passengerLegId
    offers {
      ...SeatReservationOffer
    }
    additionalReservationOffers {
      ...AdditionalReservationOffer
    }
  }
`;

export const UnpaidGroupOrderFragment = gql`
  fragment UnpaidGroupOrder on Order {
    orderNumber
    paymentDue
    state
    advanceFeePaidUntil
    finalFeePaidUntil
    groupJourneys {
      departureStation
      departureTime
      arrivalStation
      arrivalTime
      passengerJourneys {
        id
        passenger {
          type
        }
        state
      }
    }
  }
`;

export const OboCompanyFragment = gql`
  fragment OboCompany on Company {
    name
    id
  }
`;

export const JourneyOptionLegFragment = gql`
  fragment ProductAttribute on ProductAttribute {
    name
    attribute
    availability
  }

  fragment JourneyOptionLeg on JourneyOptionLeg {
    legKey
    trainNumber
    trainType
    trainAttributes
    type
    commercialLineIdentifier
    departureStation
    departureTime
    arrivalStation
    arrivalTime
    trainFillPercentage

    productAttributes {
      ...ProductAttribute
    }
  }
`;
