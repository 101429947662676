import { ParsedUrlQuery } from 'querystring';

import { entries, fromEntries } from 'utils/object';

import { GetParserType, Parser } from './queryTypes';

export type QueryStateConfig = Record<string, Parser<any>>;

export type QueryState<T extends QueryStateConfig> = { [Key in keyof T]: GetParserType<T[Key]> };

export const parseQuery = <T extends QueryStateConfig>(query: ParsedUrlQuery, config: T) =>
  fromEntries(
    entries(config).map(([key, parser]) => {
      const value = query[key as string];
      const parsed = parser.parse(value);
      return [key, parsed] as const;
    }),
  ) as QueryState<T>;
