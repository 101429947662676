import { useMemo } from 'react';

import { CompanyWithRole, ForteJwtRole, JwtCompanyRole } from 'backend/types.codegen';

import { useIdentity } from './useIdentity';

type CustomerRole = {
  name: string;
  name2?: string;
  roles: ForteJwtRole[];
};

export type CompanyProfile = {
  name: string;
  name2?: string;
  id: string;
  isAdmin: boolean;
  canPurchase?: boolean;
  roles: JwtCompanyRole[];
};

export const useGetCompanyRoles = (): {
  customerRole?: CustomerRole;
  companyRoles?: CompanyWithRole[];
} => {
  const [identity] = useIdentity();

  return useMemo(
    () =>
      !identity?.isCiamUser
        ? {}
        : {
            customerRole: identity.customerRoles?.includes(ForteJwtRole.ForteUser)
              ? {
                  name: `${identity.firstName} ${identity.lastName}`,
                  roles: identity.customerRoles as ForteJwtRole[],
                }
              : undefined,
            companyRoles: identity.companyRoles?.filter(({ roles }) =>
              roles.some(({ role }) =>
                [
                  'FORTE_CORPORATE_USER',
                  'B2B_ADMIN',
                  'FORTE_TRAVEL_AGENCY_CLERK',
                  'TRAVEL_AGENT_ADMIN',
                ].includes(role),
              ),
            ),
          },
    [identity],
  );
};
