import { entries, fromEntries } from 'utils/object';

import { QueryState, QueryStateConfig, parseQuery } from './parseQuery';

export type Parser<T> = {
  parse(value: string[] | string | undefined): T;
  serialize(value: T | null): string[] | string | undefined;
};

export type GetParserType<T extends Parser<any>> = T extends Parser<infer Type> ? Type : never;

const createParser = <T>(parser: Parser<T>) => {
  return {
    ...parser,
    withDefault(defaultValue: NonNullable<T>): Parser<NonNullable<T>> {
      return {
        parse(value) {
          const parsed = parser.parse(value);
          return parsed != null ? parsed : defaultValue;
        },
        serialize(value) {
          return value === defaultValue ? undefined : parser.serialize(value);
        },
      };
    },
  };
};

const string: Parser<string | null> = {
  parse: (v) => (typeof v === 'string' && v ? v : null),
  serialize: (v) => v || undefined,
};

const boolean: Parser<boolean | null> = {
  parse: (v) => (v === 'true' ? true : v === 'false' ? false : null),
  serialize: (v) => (v === true && 'true') || (v === false && 'false') || undefined,
};

const stringEnum = <T extends string>(values: T[]) =>
  createParser({
    parse(value) {
      return value && values.includes(value as T) ? (value as T) : null;
    },
    serialize: (value) => value || undefined,
  });

const integer: Parser<number | null> = {
  parse(value) {
    if (typeof value !== 'string') {
      return null;
    }
    const number = parseInt(value, 10);
    return Number.isNaN(number) ? null : number;
  },
  serialize: (value) => (value !== null ? value.toFixed(0) : undefined),
};

const array = <T>(parser: Parser<T | null>) =>
  createParser<T[] | null>({
    parse(value) {
      if (Array.isArray(value)) {
        const parsedArray = value.flatMap((item) => {
          const parsed = parser.parse(item);
          return parsed !== null ? parsed : [];
        });

        return parsedArray.length > 0 ? parsedArray : null;
      } else {
        const parsed = parser.parse(value);
        return parsed !== null ? [parsed] : null;
      }
    },
    serialize(value) {
      return value !== null && value.length > 0
        ? value.flatMap((item) => {
            const serialized = parser.serialize(item);
            return typeof serialized === 'string' ? serialized : [];
          })
        : undefined;
    },
  });

const json = <T extends QueryStateConfig>(shape: T): Parser<QueryState<T> | null> =>
  createParser({
    parse(value) {
      return typeof value === 'string' ? parseQuery(JSON.parse(value), shape) : null;
    },
    serialize(value) {
      if (!value) {
        return undefined;
      }

      const serialized = entries(value)
        .map(([key, value]) => [key, shape[key].serialize(value)] as const)
        .filter(([, value]) => value !== undefined);

      return serialized.length > 0 ? JSON.stringify(fromEntries(serialized)) : undefined;
    },
  });

export const queryTypes = {
  string: createParser(string),
  boolean: createParser(boolean),
  integer: createParser(integer),
  json,
  stringEnum,
  array,
};
