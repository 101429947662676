import { useIsMounted } from 'contexts/isMounted';
import { useStableObject } from 'hooks/useStableObject';
import { useVariable } from 'hooks/useVariable';
import { useRouter } from 'next/navigation';
import { ParsedUrlQuery } from 'querystring';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { setQuery, useQuery } from 'utils/navigation';
import { entries, fromEntries, omitUndefineds } from 'utils/object';
import qs from 'utils/qs';
import { QueryState, QueryStateConfig, parseQuery } from './parseQuery';

type Config = {
  history?: 'replace' | 'push';
  scroll?: boolean;
};

export const useQueryStates = <T extends QueryStateConfig>(
  config: T,
  { history: defaultHistory = 'replace', scroll: defaultScroll = false }: Config = {},
) => {
  if ('role' in config) {
    throw new Error(
      'Using role as query parameter will cause role switching in useChangeRoleFromUrl',
    );
  }

  const isReady = useIsMounted();

  const router = useRouter();

  const query = useQuery();

  const stableObject = useStableObject({
    config,
    router,
  });

  const state = useMemo<QueryState<T>>(() => {
    const { config } = stableObject;
    return parseQuery(query, config);
  }, [query, stableObject]);

  const stateRef = useVariable(state);

  const pendingQueryChangeRef = useRef<ParsedUrlQuery | null>(null);

  useEffect(() => {
    const pendingQueryChange = pendingQueryChangeRef.current;

    if (pendingQueryChange) {
      setQuery(omitUndefineds(pendingQueryChange));
      pendingQueryChangeRef.current = null;
    }
  }, []);

  const setState = useCallback(
    async (
      newState: Partial<QueryState<T>> | ((state: QueryState<T>) => Partial<QueryState<T>>),
      { history = defaultHistory, scroll = defaultScroll }: Config = {},
    ): Promise<void> => {
      const { config } = stableObject;
      const newValues = typeof newState === 'function' ? newState(stateRef.current) : newState;

      if (newValues === stateRef.current) {
        return;
      }

      const serialized = fromEntries(
        entries(newValues).map(([key, value]) => [key, config[key].serialize(value)]),
      );

      if (isReady) {
        const fn = history === 'push' ? window.history.pushState : window.history.replaceState;
        fn(null, '', `?` + qs.stringify(omitUndefineds({ ...query, ...serialized })));
        if (scroll) {
          window.scrollY = 0;
        }
      } else {
        pendingQueryChangeRef.current = {
          ...(pendingQueryChangeRef.current || query),
          ...serialized,
        };
      }
    },
    [defaultHistory, defaultScroll, isReady, query, stableObject, stateRef],
  );

  return [state, setState, { isReady }] as const;
};
