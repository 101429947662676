'use client';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';

import { createProvider } from 'utils/createProvider';

import { ScreenReaderAnnouncement } from '.';

const [useScreenReaderAnnouncements, Provider] = createProvider<
  [ScreenReaderAnnouncement[], Dispatch<SetStateAction<ScreenReaderAnnouncement[]>>]
>('ScreenReaderAnnouncementsProvider');

const ScreenReaderAnnouncementsProvider = ({ children }: { children: ReactNode }) => {
  const state = useState<ScreenReaderAnnouncement[]>([]);

  return <Provider value={state}>{children}</Provider>;
};

export { ScreenReaderAnnouncementsProvider, useScreenReaderAnnouncements };
