import { useEffect, useState } from 'react';

import { getLoggedInCookie } from 'utils/cookie';

export const useLoggedInCookie = (): boolean => {
  const [loggedIn, setLoggedIn] = useState<boolean>(getLoggedInCookie());

  useEffect(() => {
    const intervalId = setInterval(() => setLoggedIn(getLoggedInCookie()), 500);
    setLoggedIn(getLoggedInCookie());
    return () => clearInterval(intervalId);
  }, [setLoggedIn]);

  return loggedIn;
};
