import { CSSProperties, ReactElement, ReactNode, forwardRef } from 'react';

import { Card, IconComponent } from '@vrfi/web-components';

import utils from '@vrfi/web-components/css/utils.module.css';

import { Box, Text } from 'components/cssModules';

import styles from './NoticeBox.module.css';

export interface CommonNoticeProps {
  Icon?: IconComponent;
  button?: ReactElement;
  heading?: ReactElement | string;
  link?: ReactElement;
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  tabIndex?: number;
  color?: 'white' | 'grey15' | 'blue20' | 'yellow27' | 'red10';
}

interface NoticeBoxProps extends CommonNoticeProps {
  variant?: 'smallCard' | 'smallBorderCard' | 'mediumCard' | 'mediumBorderCard';
}

interface NoticeImageProps extends CommonNoticeProps {
  image: ReactElement;
}

export const NoticeBox = forwardRef<HTMLDivElement, NoticeBoxProps>(function NoticeBox(
  { Icon, button, children, heading, link, variant, color, ...props },
  ref,
) {
  variant = variant ?? 'mediumCard';
  const isSmall = variant.startsWith('small');
  const headingProps = isSmall ? ({ size: 16 } as const) : ({ title4: true } as const);

  return (
    <Box spacing={isSmall ? 's08Row' : 's16Column'} variant={variant} color={color}>
      <div ref={ref} {...props}>
        {Icon && <Icon size={isSmall ? 1.25 : 3} />}
        <Box as="div" className={styles.box} spacing={isSmall ? 's00Column' : 's16Column'}>
          {heading && (
            <Text as="h2" {...headingProps}>
              {heading}
            </Text>
          )}
          <div className={`${styles.content} ${utils.marginTrimBlock}`}>{children}</div>
          {button}
          {link}
        </Box>
      </div>
    </Box>
  );
});

export function NoticeImage({ Icon, button, children, heading, link, ...props }: NoticeImageProps) {
  return (
    <Card {...props} contentSpacing="s16">
      {Icon && <Icon size={3} />}
      <Box as="div" className={styles.card} spacing="s16Column">
        {heading && (
          <Text as="h2" title4>
            {heading}
          </Text>
        )}
        <div className={`${styles.content} ${utils.marginTrimBlock}`}>{children}</div>
        {button}
        {link}
      </Box>
    </Card>
  );
}
